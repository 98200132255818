<template>
	<div class="container">
		<!-- 头部图片 -->
		<div class="top">
			<div class="time_show">
				<div class="num1">{{$route.query.item.points}}分</div>
				<div class="num2">共用时：<span>{{$route.query.item.total_date}}</span></div>
			</div>
			<img src="../../assets/img/top2.png" alt="">
		</div>
		<!-- 答题题目 -->
		<div class="dati">
			<div style="font-size: 24px;font-weight: bold;padding: 20px 0px 0px 20px;color: #169C9D;">错题解析</div>
			<!-- 单选 -->
			<div class="dati_all">
				<!-- 题目 -->
				<div v-if="list[index].typedata==1">
					<div class="title_row">
						<label class="number">{{index+1}}.</label>
						<div class="title">
							{{list[index].title}}
						</div>
					</div>
					<!-- 选项 -->
					<div v-for="(x,y) in list[index].options" :key="x.id">
						<div class="option" :style="x.answer?'background-color: #BBFED3;color: #169C9F;':list[index].answer.ans.indexOf(x.key)==-1?'':'background-color:#F2B8B7'">
							<div class="option_key">{{x.key}}.</div>
							<div class="option_value">{{x.value}}</div>
						</div>
					</div>
				</div>
				<div v-if="list[index].typedata==2">
					<div class="title_row">
						<label class="number">{{index+1}}.</label>
						<div class="title">
							{{list[index].title}}
						</div>
					</div>
					<!-- 选项 -->
					<div v-for="(x,y) in list[index].options" :key="x.id">
						<div class="option" :style="x.answer?'background-color: #BBFED3;color: #169C9F;':list[index].answer.ans.indexOf(x.key)==-1?'':'background-color:#F2B8B7'">
							<div class="option_key">{{x.key}}.</div>
							<div class="option_value">{{x.value}}</div>
						</div>
					</div>
				</div>
				<div v-if="list[index].typedata==3">
					<div class="title_row">
						<label class="number">{{index+1}}.</label>
						<div class="title">
							{{list[index].title}}
						</div>
					</div>
					<!-- 选项 -->
					<div v-for="(x,y) in list[index].options" :key="x.id">
						<div class="option" :style="list[index].answer.ans.indexOf(x.key)==-1?'background-color: #BBFED3;color: #169C9F;':'background-color:#F2B8B7'">
							<div class="option_value">{{x.value}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="mine_answer">
				<div class="daan_real" style="color: #de0000;">
					<span>你的答案：{{proArray(list[index].answer.ans)==1?'对':proArray(list[index].answer.ans)==0?'错':proArray(list[index].answer.ans)}}</span>
				</div>
				<div class="daan_real" style="color: #1aa94e;">
					正确答案：{{proArray(list[index].trueAnswer==1?'对':proArray(list[index].trueAnswer)==0?'错':proArray(list[index].trueAnswer))}}
				</div>
			</div>
			<div class="answer_content">{{list[index].answercontent==''|| list[index].answercontent==null?'':list[index].answercontent}}</div>
			<div class="dati_button">
				<div class="dati_btn_box">
					<!-- <div :style="index==0?'background: #D9D9D9':''" class="dati_button_btn" @click="prevQue()">上一题</div> -->
					<div :style="index==list.length-1?'background: #D9D9D9':'background-color: #36CED1;'" class="dati_button_btn"
						@click="nextQue()">下一题</div>
					<div class="dati_button_btn"  @click="$router.go(-1)">退出</div>
				</div>
				<!-- <div class="dati_button_btn" @click="backIndex()">返回</div> -->
			</div>
		</div>
		<img src="../../assets/img/zbfw.png" style="width: 75%;margin: 20px auto 0px;display: block;" alt="">
	</div>
</template>

<script>
	import {
		getDetail
	} from '@/api'
	import {
		mapState,
		mapActions
	} from 'vuex'
	export default {
		name: "cj",
		data() {
			return {
				time: '',
				// 答题历史记录
				list: [],
				id: '',
				index: 0,
				yesDaan: [],
			}
		},
		created() {
			// console.log(this.$route.query.item)
			this.id = this.$route.query.item.id;
			this.getHistory()
		},
		mounted() {
			let time = this.$route.query.tiem;
			let m = parseInt(time / 60 % 60)
			let s = parseInt(time % 60);
			this.time = (m < 10 ? '0' + m : m) + '分' + (s < 10 ? '0' + s : s) + '秒'
		},
		methods: {
			...mapActions(['changeTime', 'changeHistory']),
			// 获取模拟答题用时
			backIndex(){
				this.$router.go(-1)
			},
			getTime() {
				this.changeTime({
					answerTime: sessionStorage.getItem('time')
				})
				this.time = this.$store.state.answerTime;
			},
			//处理数组答案显示
			proArray(arr){
				console.log(arr[0])
				let text = JSON.stringify(arr)
				text = text.replace(/"/g,'').replace("[",'').replace("]",'')
				return text;
			},
			//上一题
			prevQue() {
				if (this.index == 0) {
					return false;
				}
				this.index = this.index - 1
			},
			//下一题
			nextQue() {
				console.log(this.list)
				console.log(this.list[this.index])
				if (this.index == this.list.length - 1) {
					return false;
				}
				this.index = this.index + 1
			},
			// 获取历史答题记录
			getHistory() {
				let data = {
					id: this.id
				}
				getDetail(data).then(res => {
					if(res.data.code==1){
						this.list=res.data.data
						this.addFormalAnswer(res.data.data)
						this.getReall()
					}else{
						this.$toast.fail(res.data.msg)
					}
				})
			},
			//获取正确答案
			getReall(){
				this.list.forEach((item, index) => {
					// 获取正确答案
					if(item.typedata==3){
						this.list[index].trueAnswer = [];
						this.list[index].trueAnswer.push(item.judgedata)
					}else{
						let trueAnswer = item.options.filter(e => {
							return e.answer;
						})
						this.list[index].trueAnswer = [];
						trueAnswer.forEach((qitem,qindex)=>{
							this.list[index].trueAnswer.push(qitem.key)
						})
					}
				})
				// console.log(this.list)
			},
			// 在选项中添加字段
			addFormalAnswer(formalAnswer) {
				formalAnswer =  Object.values(formalAnswer);
				formalAnswer.forEach((item, index) => {
					if (item.typedata == 1) {
						item.options = item.options.map((x, y) => {
							return {
								K: x.K,
								key: x.key,
								value: x.value,
								answer: x.answer,
							}
						})
					}else if(item.typedata == 2){
						item.options = item.options.map((x, y) => {
							if(item.answer.ans.indexOf(x.key)>=0){
								x.myans=0;
							}else{
								x.myans=1;
							}
							return {
								K: x.K,
								key: x.key,
								value: x.value,
								answer: x.answer,
								myans: x.myans,
							}
						})
					} else {
						let arr = {
							key: 1,
							value: '对',
						}
						let arr1 = {
							key: 0,
							value: '错',
						}
						item.options=[];
						item.options.push(arr, arr1)
					}
				})
			},
		},
	}
</script>

<style scoped lang="scss">
	.show_ans{
		width: 120px;
		height: 40px;
		line-height: 40px;
		text-align: center;
		font-size: 24px;
		color: white;
	}
	.answer_content{
		width: 85%;
		font-size: 26px;
		margin: 0 auto 50px auto;
		padding-bottom: 50px;
		// border-bottom: 1px solid #707070;
	}
	.container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding-bottom: 50px;
		background: url(../../assets/img/allbanner.png) no-repeat center 0;
		background-size: cover;
		padding-top: 50px;
		overflow: hidden;
		.top {
			width: 85%;
			height: 260px;
			background-size: 100% 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 50px;
			padding-top: 80px;
			padding-left: 100px;
			img{
				width: 60%;
				// float: right;
			}
			.time_show {
				width: 260px;
				height: 160px;
				font-size: 50px;
				color: white;
				display: flex;
				flex-direction: column;
				color: #333333;
				.num1{
					font-weight: bold;
					font-size: 100px;
					color: #fb4e3e;
				}
				.num2{
					margin-top: 10px;
					font-size: 30px;
					span{
						font-size: 35px;
						font-weight: bold;
					}
				}
			}
		}

		.time {
			width: 85%;
			height: 80px;
			background: #fff;
			border-radius: 20px;
			margin: 0 auto;
			font-size: 30px;
			font-weight: 600;
			line-height: 80px;
			display: flex;
			justify-content: space-around;

			.timeflex {
				display: flex;
				flex-direction: row;
				align-items: center;

				.t {
					color: red;
					font-size: 35px;
				}
			}
		}

		.dati {
			width: 85%;
			background: #fff;
			border-radius: 20px;
			margin: -60px auto 0px;
			overflow-y: scroll;
			position: relative;

			.dati_all {
				padding: 30px;
				.title_row {
					width: 95%;
					display: flex;
					flex-direction: row;
					justify-content: space-around;
					padding-bottom: 40px;
					margin: auto;
					font-size: 28px;
					font-weight: bold;

					.number {
						// width: 55px;
						// height: 55px;
						// background: #CCCCCC;
						// color: #fff;
						// border-radius: 50%;
						// text-align: center;
						// line-height: 50px;
						// font-size: 30px;
					}

					.title {
						width: 550px;
						// font-size: 35px;
						font-weight: 600;
						align-self: center;
					}
				}
				.daan_real{
					padding: 10px 0px;
				}
				.option {
					display: flex;
					width: 95%;
					// height: 40px;
					font-size: 30px;
					align-items: center;
					margin: 0 auto 20px auto;
					background-color: #E9E9E9;
					padding-left: 20px;
					border-radius: 5px;
					.option_key {
						font-weight: 600;
					}

					.option_value {
						margin: 20px 20px;
					}
				}

				.analysis {
					width: 85%;
					margin: 0 auto;

					.correct_top {
						width: 100%;
						height: 55px;
						background-color: #E6F4FF;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.left {
							display: flex;
							align-items: center;
							font-size: 30px;
							color: #278CE6;
							margin-left: 20px;

							img {
								width: 40px;
								height: 40px;
								margin-right: 25px;
							}

						}

						.right {
							font-size: 30px;
							color: #278CE6;
							margin-right: 30px;
						}
					}

					.error_top {
						width: 100%;
						height: 55px;
						background-color: #FEE5E8;
						border-radius: 10px;
						display: flex;
						align-items: center;
						justify-content: space-between;

						.left {
							display: flex;
							align-items: center;
							font-size: 30px;
							color: #E72727;
							margin-left: 20px;

							img {
								width: 40px;
								height: 40px;
								margin-right: 25px;
							}

						}

						.right {
							font-size: 30px;
							color: #E72727;
							margin-right: 30px;
						}
					}

					.analysis_bottom {
						width: 100%;
						height: 80px;
						background-color: #F4F4F4;
						border-radius: 10px;
						font-size: 30px;
						line-height: 80px;

						div:first-child {
							margin-left: 35px;
							color: #949494;
						}

						div:nth-child(n) {
							margin-right: 10px;
						}
					}

					.analysis_bottom_1 {
						display: flex;
					}
				}
			}
			.mine_answer{
				display: flex;
				font-size: 30px;
				padding: 0px 40px 50px;
				div:nth-of-type(1){
					margin-right: 60px;
				}
			}

			.dati_button {
				width: 100%;
				.dati_btn_box {
					display: flex;
					width: 580px;
					margin: 0px auto 30px;
					justify-content: space-between;
				}
			
				.dati_button_btn {
					margin: 0 auto;
					width: 260px;
					height: 70px;
					background-color: #169C9F;
					color: #fff;
					font-size: 30px;
					border-radius: 5px;
					text-align: center;
					line-height: 70px;
				}
			}
		}

		.correct {
			background-color: #318DD6 !important;
			color: #fff;
		}

		.error {
			background-color: #E62526 !important;
			color: #fff;
		}
	}
</style>
